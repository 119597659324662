import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./default.module.scss";
import "../index.scss";
import { Link } from "gatsby";

const Layout = ({children}) => {
    return <div className={styles.layout} id="layout">
        <a className={styles.quickJump} href={"#sidenav"} tabIndex="1">Jump to navigation</a>
        <a className={styles.quickJump} href={"#content"} tabIndex="2">Jump to content</a>

        <div className={styles.topbar}>
            <nav>
                <Link to={"/#layout"} title="Home"><StaticImage src="../assets/chunk_studios_logo.svg" alt="Logo" /></Link>

                <a href={"#sidenav"} className={styles.menuBtn}>Menu</a>
            </nav>
        </div>
        <div className={styles.content}>
            {children}
        </div>
        <footer>&copy; {(new Date()).getFullYear()}, Chunk Studios | <Link to="/imprint">Imprint</Link></footer>
    </div>
}

export default Layout;